<template>
  <v-expansion-panel>
    <v-form ref="form" lazy-validation>
    <v-expansion-panel-header class="headline">
      <div>
        <span>{{ $tc('label.apuracao', 1)}}</span>
        <popover-lista id="popover_apuracao" :objeto="acaoFormatada" :campos="listaCamposPopover()"/>
        <v-tooltip v-if="possuiMemoriaCalculo" top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" :disabled="bloquearBotaoExportacao" @click.stop="verificarExistenciaNotas">notes</v-icon>
          </template>
          <span>{{ $t('message.visualizar_notas_clique_aqui') }}</span>
        </v-tooltip>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <card-info :campos="listaCampos()" v-if="getProdutoMetadado" :objeto="acaoFormatada">
        <template slot="antes">
          <v-col cols="12" sm="6" md="3" class="pl-0" v-if="acao.metas">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="400"
              bottom
              offset-y>
              <template v-slot:activator="{ on }">
                <v-select
                  v-model="item"
                  :items="items"
                  append-icon
                  class="select--sem--linha"
                  :label="$tc('label.meta', 1)"
                  multiple
                  readonly>
                  <template v-slot:selection="data">
                    <v-icon v-on="on">{{ data.item }}</v-icon>
                  </template>
                </v-select>
              </template>

              <v-card>
                <v-data-table
                  :headers="headers()"
                  :items="acao.metas"
                  hide-default-footer
                  :no-data-text="$t('label.tabela_sem_conteudo')">
                  <template v-slot:item.meta_de="{ item }">
                    <span v-text="exibirMeta(item.vlrMetaDe)"></span>
                  </template>
                  <template v-slot:item.meta_ate="{ item }">
                    <span v-if="item.vlrMetaAte" v-text="exibirMeta(item.vlrMetaAte)"></span>
                  </template>
                  <template v-slot:item.bonificacao_pagamento="{ item }">
                    <span v-text="exibirPagamento(item.vlrBonificacao)"></span>
                  </template>
                </v-data-table>
              </v-card>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6" md="3" class="pl-0" v-if="acao.meta">
            <input-money
              v-if="!calculoVolume"
              :label="$tc('label.meta', 1)"
              v-model="acao.meta"
              class="v-text-field"
              disabled
              :hint="String(acaoFormatada.valorRealizadoOriginal)"
              :persistent-hint="true"/>
            <v-text-field
              v-else
              :label="$tc('label.meta', 1)"
              v-model="acao.meta"
              type="Number"
              min="0"
              :suffix="acao.siglaUnidadeMedida"
              disabled
              :hint="String(acaoFormatada.valorRealizadoOriginal)"
              :persistent-hint="true"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3" class="pl-0" v-if="acao.meta">
            <input-money
              :label="$tc('label.valor', 1)"
              v-model="acao.valorBonificacao"
              class="v-text-field"
              disabled
              :persistent-hint="true"/>
          </v-col>

          <v-col cols="12" sm="6" md="3" class="pl-0">
            <input-money
              v-if="!calculoVolume"
              :label="$tc('label.realizado', 1)"
              v-model="acao.valorRealizado"
              class="v-text-field"
              :disabled="!podeAlterarVlrRealizado"
              :hint="String(acaoFormatada.valorRealizadoOriginal)"
              :persistent-hint="true"/>
            <v-text-field
              v-else
              :label="$tc('label.realizado', 1)"
              v-model="acao.valorRealizado"
              type="Number"
              min="0"
              :suffix="acao.siglaUnidadeMedida"
              :disabled="!podeAlterarVlrRealizado"
              :hint="String(acaoFormatada.valorRealizadoOriginal)"
              :persistent-hint="true"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="pl-0" style="position:relative">
            <input-money
              :id="`valor_recebimento_${acao.idAcao}`"
              :label="$tc('label.valor_pagamento', 1)"
              v-model="acao.vlrConfApuracao"
              class="v-text-field"
              @input="mudancaJustificativaAlteracaoValor"
              :disabled="!podeAlterarVlrPgto || acao.isFluxoParalelo"
              :hint="String(acaoFormatada.vlrConfApuracaoOriginal)"
              :persistent-hint="true"/>
            <v-btn
              v-if="exibeIconeRateio"
              icon
              style="position: absolute;right: 10px;top: 20px;"
              @click="exibeRateioValores">
              <v-icon>visibility</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="4" sm="3" v-if="possuiCoeficienteCalculo && coeficienteCalculo !== null">
            <v-text-field
              id="valorBase"
              type="number"
              :disabled="true"
              :label="`${$tc('label.valor_base', 1)}`"
              v-model="coeficienteCalculo">
            </v-text-field>
          </v-col>

          <v-col cols="14" sm="6" md="3" class="pl-0" style="position:relative" v-if="habilitarJustificativa">
            <v-textarea
              id="ApuracaoAcaoDetalhesApuracao_justificativa"
              name="ApuracaoAcaoDetalhesApuracao_justificativa"
              ref="ApuracaoAcaoDetalhesApuracao_justificativa"
              :value="justificativaAlteracaoValorPagamento"
              rows="1"
              @input="mudancaJustificativa($event)"
              :rules="[rules.required]"
              :disabled="!podeAlterarValores"
              :label="`${$t('label.justificativa_alteracao_valor_pagamento')} *`"
              :counter="500"
              maxlength="500">
            </v-textarea>
          </v-col>

        </template>
      </card-info>

      <apuracao-acao-detalhes-apuracao-rateio
        ref="modalRateio"
        v-if="acao"
        :id-apuracao="acao.idApuracao"
        :vlr-conf-apuracao="acao.vlrConfApuracao"
        :vlr-reservado="acao.vlrReservado"
        :status-apuracao="acao.statusApuracao"
        :tipos-verbas="acao.tiposVerbas"
        :periodos-orcamentarios="acao.periodosOrcamentarios"
        :somente-leitura="!podeAlterarValores || acao.isFluxoParalelo"
        />
    </v-expansion-panel-content>
    </v-form>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CardInfo from '../../shared-components/CardInfo';
import PopoverLista from '../../shared-components/PopoverLista';
import InputMoney from '../../shared-components/inputs/InputMoney';
import {
  convertToMoney,
  convertToPercent,
  capitalize,
  getMoney,
} from '../../common/functions/helpers';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import exportacao from '../../common/functions/exportacao';
import ApuracaoAcaoDetalhesApuracaoRateio from '../apuracao-acao/ApuracaoAcaoDetalhesApuracaoRateio';

export default {
  props: {
    acao: Object,
    configuracao: Object,
    justificativaAlteracaoValorPagamento: String,
    isContrato: {
      type: Boolean,
      default: false,
    },
    possuiCoeficienteCalculo: {
      type: Boolean,
      default: false,
    },
    coeficienteCalculo: {
      type: Number,
      default: null,
    },
  },
  components: {
    CardInfo,
    PopoverLista,
    InputMoney,
    ApuracaoAcaoDetalhesApuracaoRateio,
  },
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
      apuracaoAcaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),
      apuracaoAcaoListagemResource: this.$api.apuracaoAcaoListagem(this.$resource),
      workflowApuracaoAcaoResource: this.$api.workflowApuracaoAcao(this.$resource),
      solicitanteFluxo: false,
      bloquearBotaoExportacao: false,

      campos: [
        { campo: 'valorAcao', label: this.$tc('label.valor_acao', 1) },
      ],
      camposPopover: [
        { campo: 'periodoApuracao', label: this.$tc('label.periodo_apuracao', 1) },
        { campo: 'calculoApuracao', label: this.$tc('label.calculo_apuracao', 1) },
        { campo: 'nomeUnidadeMedida', label: this.$tc('label.unidade_medida', 1) },
        { campo: 'valorBonificacao', label: this.$tc('label.forma_bonificacao', 1) },
        { campo: 'calculoBonificacao', label: this.$tc('label.calculo_bonificacao', 1) },
        { campo: 'baseCalculo', label: this.$tc('label.base_calculo', 1) },
        { campo: 'descontos', label: this.$tc('label.desconto', 1) },
      ],
      menu: false,
      item: 'attach_money',
      items: ['attach_money'],
      acaoFormatada: {},
      valorAtual: null,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getProdutoMetadado',
      'getAcaoMetadado',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...generateComputed('APU_ACAO', [
      'canEdit',
    ]),
    ...generateComputed('APU_CONTR', [
      'canAccessCRUD',
    ]),
    podeEditar() {
      return (this.canEdit && !this.isContrato) || (this.canAccessCRUD && this.isContrato);
    },
    podeAlterarValores() {
      return this.podeEditar && (this.solicitanteFluxo || this.isContrato)
        && (this.acao.statusApuracao === 'AGUARDANDO_APURACAO' || this.acao.statusApuracao === 'EM_ANALISE')
        && !this.acao.isFluxoParalelo;
    },
    podeAlterarVlrPgto() {
      return this.podeEditar
        && this.configuracao.passo5
        && this.configuracao.passo5.apuracao
        && this.configuracao.passo5.apuracao.indEdicaoValorPagamento
        && (this.acao.statusApuracao === 'AGUARDANDO_APURACAO'
          || this.acao.statusApuracao === 'EM_ANALISE')
        && (this.solicitanteFluxo || this.isContrato);
    },
    podeAlterarVlrRealizado() {
      return this.podeEditar
        && this.configuracao.passo5
        && this.configuracao.passo5.apuracao
        && this.configuracao.passo5.apuracao.indEdicaoValorRealizado
        && (this.acao.statusApuracao === 'AGUARDANDO_APURACAO'
          || this.acao.statusApuracao === 'EM_ANALISE')
        && (this.solicitanteFluxo || this.isContrato);
    },
    habilitarJustificativa() {
      if (this.acao.vlrConfApuracao > this.acao.vlrConfApuracaoOriginal) {
        this.$emit('ApuracaoDetalhes__pagamentoAlterado', false);
      } else {
        this.$emit('ApuracaoDetalhes__pagamentoAlterado', true);
      }
      return this.acao.vlrConfApuracao > this.acao.vlrConfApuracaoOriginal;
    },
    calculoVolume() {
      if (this.acao.calculoApuracao
        && (this.acao.calculoApuracao === 'SELLIN_VOLUME'
          || this.acao.calculoApuracao === 'SELLOUT_VOLUME')) {
        return true;
      }
      return false;
    },
    verbaPercentual() {
      if (this.acao.formaBonificacao
        && this.acao.formaBonificacao === 'PERCENTUAL') {
        return true;
      }
      return false;
    },
    possuiMemoriaCalculo() {
      return this.acaoFormatada && this.acaoFormatada.possuiMemoriaCalculo;
    },
    exibeIconeRateio() {
      return this.vinculoMultiplasContas;
    },
    vinculoMultiplasContas() {
      return this.acaoFormatada && this.acaoFormatada.qtdContasVinculadas > 1;
    },
    vinculoUnico() {
      return this.acaoFormatada && this.acaoFormatada.qtdContasVinculadas === 1;
    },
    usuarioCliente() {
      return this.usuarioLogado.tipo === 'CLIENTE';
    },
  },
  watch: {
    acao(val) {
      this.bloquearBotaoExportacao = false;
      if (val && val.statusApuracao && val.idApuracao) {
        this.verificarUsuarioSolicitante(val.idApuracao, val.statusApuracao);
      }
    },
  },
  methods: {
    convertToMoney,
    ...mapActions('metadados', [
      'setProdutoMetadado',
    ]),
    mudancaJustificativaAlteracaoValor() {
      if (this.acao.vlrConfApuracao === this.acao.vlrReservado === this.acao.valorAcao) {
        this.$emit('ApuracaoDetalhes__AlteracaoJustificativa',
          this.justificativaAlteracaoValorPagamento);
      }
    },
    mudancaJustificativa(event) {
      this.$emit('ApuracaoDetalhes__AlteracaoJustificativa',
        event);
    },
    verificarExistenciaNotas() {
      const params = {
        idApuracao: this.acao.idApuracao,
      };

      this.apuracaoAcaoListagemResource.verificarDadosExportacao(params, params)
        .then((res) => {
          if (res.body) {
            this.exportar();
          } else {
            this.bloquearBotaoExportacao = true;
            this.$toast(this.$t('message.sem_dados_exportacao_apuracao'));
          }
        });
    },
    exportar() {
      const params = {
        id_apuracao: this.acao.idApuracao,
      };
      exportacao.exportar(null, 'notas_sellin_memoria_apuracao', this, params);
    },
    verificarUsuarioSolicitante(idApuracao, status) {
      if (status === 'AGUARDANDO_APURACAO' || status === 'EM_ANALISE') {
        this.workflowApuracaoAcaoResource.solicitante({ idApuracao })
          .then((res) => {
            this.solicitanteFluxo = res.data.usuarioHabilitado;
          });
      }
    },
    buscaLabelExtensao(label) {
      if (!label || !this.getAcaoMetadado) {
        return '';
      }

      const obj = (this.getAcaoMetadado.mapaCamposDinamicos || {})[label];
      return obj ? obj.labelCampo : '';
    },
    listaCampos() {
      const campos = [].concat(this.campos);
      if (this.configuracao.passo3) {
        const { listaProduto, listaCarteiraCliente } = this.configuracao.passo3;
        const camposApuracao = listaProduto.filter((p) => p.fluxos.includes('PLANEJAMENTO') && !p.excecao);
        camposApuracao.push(...(listaCarteiraCliente || []).filter((p) => p.fluxos.includes('APURACAO')));

        camposApuracao.forEach((a) => campos.push({
          campo: a.label,
          extensao: !a.indProduto,
          label: a.indProduto ? a.label[0].toUpperCase() + a.label.slice(1)
            : this.montaLabelExtensao(a.label),
        }));
      }
      return campos;
    },
    listaCamposPopover() {
      const campos = [].concat(this.camposPopover);
      if (this.configuracao.passo3) {
        const { listaProduto } = this.configuracao.passo3;
        const camposApuracao = listaProduto.filter((p) => p.fluxos.includes('PLANEJAMENTO') && p.excecao);
        camposApuracao.forEach((a) => campos.push({ campo: a.label, excecao: true }));
      }
      return campos;
    },
    formatarObjeto() {
      this.acaoFormatada = { ...this.acao };
      this.valorAtual = this.acao.vlrConfApuracao;
      if (this.acao && this.acao.valorAcao) {
        if (this.acao.indVerbaPercentual) {
          this.acaoFormatada.valorAcao = convertToPercent(this.acao.valorAcao);
        } else {
          this.acaoFormatada.valorAcao = convertToMoney(this.acao.valorAcao);
        }
      }

      this.acaoFormatada.vlrConfApuracao = convertToMoney(this.acao.vlrConfApuracao);
      this.acaoFormatada.vlrReservado = convertToMoney(this.acao.vlrReservado);
      this.acaoFormatada.vlrConfApuracaoOriginal = convertToMoney(this.acao.vlrConfApuracaoOriginal);

      if (this.acaoFormatada.valorBonificacao) {
        if (this.acao.formaBonificacao && this.acao.formaBonificacao === 'PERCENTUAL') {
          this.acaoFormatada.valorBonificacao = convertToPercent(this.acao.valorBonificacao);
        } else {
          this.acaoFormatada.valorBonificacao = convertToMoney(this.acao.valorBonificacao);
        }
      }

      if (!this.acao.calculoApuracao || (this.acao.calculoApuracao && this.acao.calculoApuracao.includes('VALOR'))) {
        this.acaoFormatada.valorRealizado = convertToMoney(this.acao.valorRealizado);
        this.acaoFormatada.valorRealizadoOriginal = convertToMoney(this.acao.valorRealizadoOriginal);
      } else {
        this.acaoFormatada.valorRealizado = `${this.acao.valorRealizado} ${this.acao.siglaUnidadeMedida}`;
        this.acaoFormatada.valorRealizadoOriginal = `${this.acao.valorRealizadoOriginal} ${this.acao.siglaUnidadeMedida}`;
      }

      if (this.acao && this.acao.periodoApuracao) {
        this.acaoFormatada.periodoApuracao = capitalize(this.acao.periodoApuracao);
      }

      if (this.acao && this.acao.calculoBonificacao) {
        this.acaoFormatada.calculoBonificacao = this.$tc(`label.${this.acao.calculoBonificacao.toLowerCase()}`, 1);
      }
      if (this.acao && this.acao.baseCalculo) {
        this.acaoFormatada.baseCalculo = this.$tc(`label.${this.acao.baseCalculo.toLowerCase()}`, 1);
      }
      if (this.acao && this.acao.calculoApuracao) {
        this.acaoFormatada.calculoApuracao = this.$tc(`label.${this.acao.calculoApuracao.toLowerCase()}`, 1);
      }
      if (this.acao && this.acao.descontos) {
        this.acaoFormatada.descontos = '';
        const listaDescontos = this.acao.descontos.split(';');
        listaDescontos.forEach((p, i) => {
          this.acaoFormatada.descontos = this.acaoFormatada.descontos.concat(`${this.$tc(`label.${p.toLowerCase()}`, 1)}`);
          if (i < (listaDescontos.length - 1)) {
            this.acaoFormatada.descontos = this.acaoFormatada.descontos.concat(';');
          }
        });
      }

      this.listaCampos().filter((c) => c.extensao).forEach((c) => {
        if (this.acao && this.acao[c.campo]) {
          this.acaoFormatada[c.campo] = this.acao[c.campo].nomExtensao;
          if (!this.acaoFormatada[c.campo]) {
            if (Array.isArray(this.acao[c.campo])) {
              this.acaoFormatada[c.campo] = '';
              this.acao[c.campo].forEach((o, i) => {
                this.acaoFormatada[c.campo] = this.acaoFormatada[c.campo].concat(o.nomExtensao);
                if (i < (this.acao[c.campo].length - 1)) {
                  this.acaoFormatada[c.campo] = this.acaoFormatada[c.campo].concat(';');
                }
              });
            } else {
              this.acaoFormatada[c.campo] = this.acao[c.campo];
            }
          }
        }
      });

      this.listaCamposPopover().filter((c) => c.excecao).forEach((c) => {
        if (this.acao && this.acao[c.campo]) {
          this.acaoFormatada[c.campo] = this.acao[c.campo].nomExtensao;
          if (!this.acaoFormatada[c.campo]) {
            if (Array.isArray(this.acao[c.campo])) {
              this.acaoFormatada[c.campo] = '';
              this.acao[c.campo].forEach((o, i) => {
                this.acaoFormatada[c.campo] = this.acaoFormatada[c.campo].concat(o.nomExtensao);
                if (i < (this.acao[c.campo].length - 1)) {
                  this.acaoFormatada[c.campo] = this.acaoFormatada[c.campo].concat(';');
                }
              });
            } else {
              this.acaoFormatada[c.campo] = this.acao[c.campo];
            }
          }
        }
      });
    },
    montaLabelExtensao(campo) {
      const metadado = Object.values(this.getProdutoMetadado.mapaEntidades)
        .filter((dep) => dep.label === campo)[0];

      return metadado.entidadeEstrangeira;
    },
    headers() {
      return [
        {
          text: this.$t('label.crescimento_de'), value: 'meta_de', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.crescimento_ate'), value: 'meta_ate', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.verba'), value: 'bonificacao_pagamento', sortable: false, align: 'center',
        },
      ];
    },
    exibirMeta(valor) {
      return convertToPercent(valor);
    },
    exibirPagamento(valor) {
      if (this.verbaPercentual) {
        return convertToPercent(valor);
      }
      return convertToMoney(valor);
    },
    validarForm() {
      return this.$refs.form.validate();
    },
    validaSaldoContaUnica() {
      const { idApuracao, vlrConfApuracao } = this.acao;
      return this.apuracaoAcaoResource
        .validaSaldoContaUnica({ idApuracao, valorAlterado: vlrConfApuracao })
        .then((res) => {
          const validacao = res.data;
          const { suficiente } = validacao;

          if (!suficiente) {
            this.$emit('ApuracaoDetalhes__AusenciaSaldo', {
              periodo: validacao.periodo,
              saldo: getMoney(validacao.saldo),
            });
          }

          return suficiente;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    valorMenorQueValorUtilizado() {
      return this.acao.vlrConfApuracao < this.acao.vlrUtilizado;
    },
    temValorUtilizado() {
      return this.acao.vlrUtilizado && this.acao.vlrUtilizado > 0;
    },
    salvar(valoresPendentes) {
      const parametros = {};
      parametros.valorRealizado = this.acao.valorRealizado;
      parametros.vlrConfApuracao = this.acao.vlrConfApuracao;
      parametros.justificativaAlteracaoValorPagamento = this.justificativaAlteracaoValorPagamento;
      parametros.valorRealizadoOriginal = this.valorAtual;
      parametros.id = this.acao.idApuracao;

      if (valoresPendentes) {
        parametros.valoresPendentes = valoresPendentes;
      }

      this.apuracaoAcaoResource.atualizar(parametros)
        .then(() => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          this.acaoFormatada.vlrConfApuracao = this.acao.vlrConfApuracao;
          this.valorAtual = this.acao.vlrConfApuracao;
          this.$emit('ApuracaoDetalhes__ApuracaoAlterada', this.acao.vlrConfApuracao);
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    exibeRateioValores() {
      this.$refs.modalRateio
        .exibirModal((valoresPendentes) => this.salvar(valoresPendentes), true);
    },
  },
  beforeMount() {
    this.setProdutoMetadado({
      resource: this.metadadoResource.listarProduto,
    });
  },
};
</script>
<style>
  .select--sem--linha > .v-input__control > .v-input__slot:before {
    width: 0% !important;
  }
  .select--sem--linha > .v-input__control > .v-input__slot:after {
    border-style: none;
  }
</style>
